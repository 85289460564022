import type { CostDetails } from '@/open-web/services/calculators/calculateContractTemplateCost'
import type {
  CustomerType,
  PriceDetails,
  TariffElement,
} from '@/shared/graphql/schema/commonBackend/graphql'
import type { EnrichedAvailableAddon } from '@/shared/services/campaignDataResolver'
import type {
  SelectedAddon,
  SelectedAddons,
  StepOptionExtraValues,
} from '@/shared/store/slices/selectedContractSlice'
import { sumPrices } from '@/shared/utils/contractTemplateUtils'
import { isNotNullOrUndefined } from '@/shared/utils/isNotNullOrUndefined'
import { getStaticAssetPath } from '@/shared/utils/staticAssets'
import {
  isEnergyDiscountElement,
  isEnergyElement,
  isMonthlyFeeDiscountElement,
  isMonthlyFeeElement,
} from '@/shared/utils/tariffElementUtils'

import type { AddonGroup, StepFormField, StepOption } from './types'

/**
 * Return object with four values:
 *
 *  monthlyFee - sum of all monthly fees elements
 *  energy - sum of all energy element
 *  monthlyFeeWithDiscount - monthlyFee minus sum of all monthlyFee discounts elements
 *  energyWithDiscount, - energy minus sum of all energy discounts elements
 */
export const calculateAddonCost = (addon: EnrichedAvailableAddon) => {
  //We are looking only for one element each type, because I have never seen addon with two discounts with same type
  const energyElement = addon.tariffElements?.find((tariffEl) => isEnergyElement(tariffEl.type))
  const monthlyFeeElement = addon.tariffElements?.find((el) => isMonthlyFeeElement(el.type))
  const energyDiscountElement = addon.discountElements?.find((el) =>
    isEnergyDiscountElement(el.type),
  )
  const monthlyFeeDiscountElement = addon.discountElements?.find((el) =>
    isMonthlyFeeDiscountElement(el.type),
  )

  const monthlyFeeUnit = monthlyFeeElement?.priceUnit
  const energyUnit = energyElement?.priceUnit

  const energy = calculateCostDetails(
    [getFirstPriceFromTariffEl(energyElement)].filter(isNotNullOrUndefined),
    energyUnit,
  )

  const monthlyFee = calculateCostDetails(
    [getFirstPriceFromTariffEl(monthlyFeeElement)].filter(isNotNullOrUndefined),
    monthlyFeeUnit,
  )

  const energyWithDiscountPriceDetails = getPricesFromTariffElements(
    [energyElement, energyDiscountElement].filter(isNotNullOrUndefined),
  )

  const monthlyFeeWithDiscountPriceDetails = getPricesFromTariffElements(
    [monthlyFeeElement, monthlyFeeDiscountElement].filter(isNotNullOrUndefined),
  )

  const monthlyFeeWithDiscount = calculateCostDetails(
    monthlyFeeWithDiscountPriceDetails,
    monthlyFeeUnit,
  )

  const energyWithDiscount = calculateCostDetails(energyWithDiscountPriceDetails, energyUnit)

  return {
    monthlyFeeWithDiscount,
    energyWithDiscount,
    monthlyFee,
    energy,
    monthlyFeeDiscountDuration: monthlyFeeDiscountElement?.duration,
    energyDiscountDuration: energyDiscountElement?.duration,
  }
}

const calculateCostDetails = (
  pirceDetails: PriceDetails[],
  priceUnit?: string | null,
): CostDetails => ({
  priceInclVat: sumPrices(pirceDetails, 'priceInclVat'),
  priceExclVat: sumPrices(pirceDetails, 'priceExclVat'),
  vatAmount: sumPrices(pirceDetails, 'vatAmount'),
  priceUnit,
})

export const getPriceBaseOnCustomerType = (price?: CostDetails, customerType?: CustomerType) => {
  if (!price) {
    return null
  }

  if (customerType === 'ENTERPRISE') {
    return price?.priceExclVat
  }
  return price?.priceInclVat
}

/**
 * Create steps form initial values base on availableAddons and selectedAddons
 */
export const getUpgradeFlowStepsFields = (
  addons: EnrichedAvailableAddon[],
  selectedAddons?: SelectedAddons,
): StepFormField[] => {
  const addonsGroupedByType = Object.entries(
    addons.reduce<Record<string, EnrichedAvailableAddon[]>>(
      (groupedAddons, addon) => ({
        ...groupedAddons,
        [addon.addonType]: [...(groupedAddons[addon.addonType] || []), addon].filter(Boolean),
      }),
      {},
    ),
  )

  return addonsGroupedByType.reduce<StepFormField[]>((fields, [addonType, addonsGroup]) => {
    //For protection against storage manipulation
    const selectedAddonOption = selectedAddons?.[addonType]

    return [
      ...fields,
      {
        status:
          selectedAddonOption !== undefined
            ? 'completed'
            : !fields.some((c) => c.status === 'initial')
              ? 'initial'
              : 'disabled',
        group: addonType as AddonGroup,
        options: [
          ...addonsGroup.map((addon) => ({
            value: addon.tariffNo,
            isSelected: selectedAddonOption?.value === addon.tariffNo,
            extraValues: selectedAddonOption?.extraValues || getDefaultAddonExtraValues(addon),
          })),
          { value: null, isSelected: selectedAddonOption?.value === null },
        ],
      },
    ]
  }, [])
}

/**
 * Return default extraValues base on selectedAddons data and cf addon product configuration
 */
export const getDefaultAddonExtraValues = (
  addon: EnrichedAvailableAddon,
): Partial<StepOption['extraValues']> => {
  if (addon.cfData?.termsAndConditionsUrl) {
    return {
      isTermsAccepted: false,
    }
  }
  return
}

export const isAllStepsCompleted = (steps: StepFormField[]) =>
  Object.values(steps).every((c) => c.status === 'completed')

export const getSelectedAddonsByGroup = (steps: StepFormField[]) =>
  steps.reduce<SelectedAddons>((selectedAddons, step) => {
    const selected = step.options.find((el) => el.isSelected)
    return selected
      ? {
          ...selectedAddons,
          [step.group]: { value: selected.value, extraValues: selected.extraValues },
        }
      : selectedAddons
  }, {})

/**
 * Each addon with extra fields need to have implemented validation method
 */
export const validateExtraValues = (
  extraValues?: StepOption['extraValues'],
): { isValid: boolean; errors?: (keyof StepOptionExtraValues)[] } => {
  if (!extraValues) {
    return { isValid: true }
  }

  const validators: Record<keyof StepOptionExtraValues, (value: unknown) => boolean> = {
    isTermsAccepted: Boolean,
  }

  const errors = Object.entries(extraValues)
    .filter(([key, value]) => !validators[key as keyof StepOptionExtraValues](value))
    .map(([key]) => key) as (keyof StepOptionExtraValues)[]

  return { isValid: !Boolean(errors?.length), errors }
}

export const getSelectedAddonFromStep = (step: StepFormField): SelectedAddon | undefined => {
  const selected = step.options.find((el) => el.isSelected)
  return selected ? { value: selected.value, extraValues: selected.extraValues } : undefined
}

export const getEvCarModelImageSrc = (brand: string) =>
  getStaticAssetPath(`/images/evCarBrands/${brand.split(' ').join('_').toLowerCase()}.svg`)

const getFirstPriceFromTariffEl = (tariffElement?: TariffElement) => tariffElement?.prices?.[0]

const getPricesFromTariffElements = (tariffElements: TariffElement[]) =>
  tariffElements.map(getFirstPriceFromTariffEl).filter(isNotNullOrUndefined)
