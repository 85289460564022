import type { IconId } from '@/shared/components/Icon'
import type { RichTextDocument } from '@/shared/components/RichText'
import type { Maybe, TariffElement } from '@/shared/graphql/schema/commonBackend/graphql'
import type {
  EnrichedAvailableAddon,
  EnrichedContractTemplate,
} from '@/shared/services/campaignDataResolver'

export const ADDON_TYPE = {
  hourlyMeasurement: 'hourlyMeasurement',
}

export const isHourlyMeasurementAddon = (addonType: string) =>
  addonType === ADDON_TYPE.hourlyMeasurement

/**
 * Method that filter out all addons that are not available under upgrade flow
 */
export const filterOutSpecialAddons = (addons: EnrichedAvailableAddon[]) =>
  addons.filter((addon) => !isHourlyMeasurementAddon(addon.addonType))

export const filterOnlySpecialAddons = (addons: EnrichedAvailableAddon[]) =>
  addons.filter((addon) => isHourlyMeasurementAddon(addon.addonType))

export type AddonItem = {
  /**
   * Addon tariffNo.
   */
  tariffNo: number
  /**
   * In most cases addon name.
   */
  primaryText: string
  /**
   * Price information.
   */
  secondaryText: string
  /**
   * Additional information as richText
   */
  additionalInformation?: RichTextDocument | null
  /**
   * Description as richText
   */
  description?: string | null
  /**
   * Addon icon.
   */
  icon: IconId
  /**
   * If true addon is bundled into contract.
   */
  isBundled: boolean
  /**
   * Set to true to indicate that addon should be handled as hourly measurement checkbox.
   */
  isHourlyMeasurementAddon: boolean
  /**
   * Addon type.
   */
  addonType: string
}

const getAddonItem = (
  addon?: Pick<
    EnrichedAvailableAddon,
    'tariffElements' | 'cfData' | 'tariffNo' | 'templateNo' | 'addonType'
  >,
): AddonItem | null =>
  addon?.tariffNo
    ? {
        icon: addon.cfData.icon as IconId,
        primaryText: addon.cfData.title || '',
        secondaryText: getOneLinePriceFromTariffElements(addon.tariffElements),
        additionalInformation: addon.cfData.additionalInformation,
        isBundled: !Boolean(addon.templateNo),
        tariffNo: addon.tariffNo,
        isHourlyMeasurementAddon: isHourlyMeasurementAddon(addon.addonType) ?? false,
        addonType: addon.addonType,
      }
    : null

const isAddonItem = (item: unknown): item is AddonItem => Boolean((item as AddonItem).tariffNo)

const getOneLinePriceFromTariffElements = (tariffElements?: Maybe<TariffElement[]>) =>
  tariffElements
    ?.map((tariffEl) => `${tariffEl.prices?.[0]?.priceInclVat} ${tariffEl.priceUnit}`)
    .join(' + ') || ''

const shouldBeShown = (addon: AddonItem) => addon.isHourlyMeasurementAddon || addon.isBundled

export const getBundledOrPreselectedAddons = (contractTemplate?: EnrichedContractTemplate) =>
  contractTemplate
    ? [...contractTemplate.availableAddons, ...contractTemplate.bundledAddons]
        .map(getAddonItem)
        .filter(isAddonItem)
        .filter(shouldBeShown)
    : []
